<template lang="pug">
  main#main.machines
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-conveyor-belt-alt')
      section.l-section
        .l-section-h.machines-show
          b-row.mb-xl-5
            b-col
              machines-panel(ref='machinesPanel')
          b-row.mb-xl-5
            b-col
              router-view(@machine-changed='reloadMachinesPanel')
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'
import MachinesPanel from '@views/container/machines/Panel'

export default {
  name: 'machines-show',
  components: {
    WcBreadcrumb,
    MachinesPanel,
  },
  methods: {
    reloadMachinesPanel() {
      this.$refs.machinesPanel.panelReload()
    },
  },
}
</script>
